<template>
  <div id="mu">
    <top :name="text"></top>
    <p class="block" @click="rengong">进入南航人工选座</p>
    <p class="block" @click="muclick">东航订单查询</p>
    <div class="input">
      <span>姓名：</span>
      <input type="text" v-model="name" placeholder="姓名" />
    </div>
    <div class="input">
      <span>票号：</span>
      <input type="text" v-model="piao" placeholder="票号" />
    </div>
    <div class="input">
      <span>东航账号：</span>
      <input type="text" v-model="dong_admin" placeholder="登录账号" />
    </div>
    <div class="input">
      <span>东航密码：</span>
      <input type="text" v-model="dong_pwd" placeholder="登录密码" />
    </div>
    <div class="input">
      <span>支付密码：</span>
      <input type="text" v-model="zhifu_pwd" placeholder="支付密码" />
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <button class="button" @click="chaxun">查询</button>
    </div>
    <div style="display: flex; align-content: center">
      <div
        v-for="(item, index) in shuju"
        :key="index"
        style="
          font-size: 0.36rem;
          border: 1px solid #999;
          padding: 0.2rem;
          flex: 1;
        "
      >
        <p>{{ item.unit }}</p>
        <p>{{ item.mileageAmount }}</p>
      </div>
    </div>
    <div class="input">
      <span>手机号</span>
      <input type="text" v-model="phone" placeholder="手机号" />
      <button @click="yanzm">发送验证码</button>
    </div>
    <div class="input">
      <span>验证码</span>
      <input type="text" v-model="yanzhengma" placeholder="验证码" />
    </div>
    <div class="input">
      <span>行李额度</span>
      <input type="text" v-model="edu" placeholder="格式:5KG/5PC" />
    </div>
    <div class="input">
      <span>里程：</span>
      <input type="text" v-model="licheng" placeholder="格式:1000" />
    </div>
    <div style="width: 100%; display: flex; justify-content: center">
      <button class="button" @click="xiadan">下单</button>
    </div>
  </div>
</template>
  
  <script>
import top from "../components/filltop.vue";
export default {
  components: {
    top,
  },
  data() {
    return {
      text: "东航行李额",
      name: "",
      piao: "",
      dong_admin: "",
      dong_pwd: "",
      zhifu_pwd: "",
      shuju: [],
      yanzhengma: "",
      phone: "",
      licheng: "",
      edu: "",
      json: "",
      chuandi_name: "",
    };
  },
  mounted() {},
  methods: {
    rengong() {
      this.$router.push({
        name: "inset",
      });
    },
    muclick() {
      this.$router.push({
        name: "muorder",
      });
    },
    chaxun() {
      let arr = [this.name, this.piao, this.dong_admin, this.dong_pwd];
      if (arr.includes("")) {
        this.$toast.fail("信息不全");
        return false;
      }
      var load = this.$loading({
        lock: true,
        text: "查询中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios({
        url: "https://wx.yaolvxing.com/luggage/allowance/inside/ticket/validity",
        data:
          "passengerLastName=" +
          this.name.substr(0, 1) +
          "&passengerFirstName=" +
          this.name.substr(1, 99) +
          "&ticketNo=" +
          this.piao +
          "&airline=" +
          "MU" +
          "&token=" +
          "23A54261AD8B4BB8242384C48B60E25C" +
          "&account=" +
          this.dong_admin +
          "&accountPwd=" +
          this.dong_pwd +
          "&walletPwd=" +
          this.zhifu_pwd,
        method: "POST",
        timeout: 60000,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          load.close();
          if (res.data.rsCode != "000000") {
            this.$toast.fail(res.data.rsMessage);
            return false;
          }
          this.shuju = res.data.data.bageDetail;
          this.json = res.data.data.segmentList;
          this.chuandi_name = res.data.data.passengerFullName;
        })
        .catch((err) => {
          console.log(err, "接口报错");
          load.close();
          this.$toast.fail("超过1分钟或服务器错误");
        });
    },
    yanzm() {
      if ((this.phone ?? "") == "") {
        this.$toast.fail("填写手机号码");
        return false;
      }
      var load = this.$loading({
        lock: true,
        text: "正在发送验证码",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios({
        url: "https://wx.yaolvxing.com/luggage/allowance/seed",
        data: "airline=" + "MU" + "&phone=" + this.phone,
        method: "post",
        timeout: 60000,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          load.close();
          if (res.data.rsCode != "000000") {
            this.$toast.fail(res.data.rsMessage);
            return false;
          }
        })
        .catch((err) => {
          console.log(err, "接口报错");
          load.close();
          this.$toast.fail("超过1分钟或服务器错误");
        });
    },
    xiadan() {
      if ((this.json ?? "") == "") {
        this.$toast.fail("先点击查询后下单");
        return false;
      }
      var load = this.$loading({
        lock: true,
        text: "正在下单",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios({
        url: "https://wx.yaolvxing.com/luggage/allowance/inside/order",
        data:
          "airline=" +
          "MU" +
          "&ticketNo=" +
          this.piao +
          "&bageBookingSegList=" +
          JSON.stringify(this.json) +
          "&bageTotalUnit=" +
          this.edu +
          "&passengerFullName=" +
          this.chuandi_name +
          "&contactInfo=" +
          JSON.stringify({ contact: this.name, phone: this.phone }) +
          "&code=" +
          this.yanzhengma +
          "&currency=" +
          "ffp" +
          "&needMileage=" +
          this.licheng +
          "&account=" +
          this.dong_admin +
          "&accountPwd=" +
          this.dong_pwd +
          "&walletPwd=" +
          this.zhifu_pwd,
        method: "post",
        timeout: 60000,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((res) => {
          load.close();
          if (res.data.rsCode != "000000") {
            this.$toast.fail(res.data.rsMessage);
            return false;
          }
          this.$toast.success(res.data.rsMessage);
        })
        .catch((err) => {
          load.close();
          console.log(err, "接口报错");
          this.$toast.fail("超过1分钟或服务器错误");
        });
    },
  },
};
</script>
  
  <style scoped>
.block {
  display: block;
  padding: 0.2rem;
  /* width: 20VW; */
  margin: 0.2rem;
  font-size: 0.34rem;
  text-align: center;
  background: rgb(1, 42, 58);
  color: white;
}
.input {
  padding: 0.3rem 0 0.3rem 0.2rem;
  font-size: 0.34rem;
  width: 100vw;
  height: auto;
  display: flex;
  flex: 1;
}
.input input {
  width: 80vw;
}
.button {
  background: #135f7f;
  color: white;
  width: 5rem;
  letter-spacing: 0.1rem;
  border: none;
  border-radius: 60px;
  font-size: 0.4rem;
  height: 1rem;
  margin-top: 0.4rem;
}
</style>